import React from "react"
import { Widget } from '@typeform/embed-react'
import { Wrapper, Container } from "../styles"
import SEO from "../components/seo"

const SignupPage = () => {
  return (
    <>
      <SEO
        title="Redeem your Rachio Pro Rewards Credits"
        description="Fill out the form to redeem your Rachio Pro Rewards Credits!"
        pathname="/rewards-credit/"
        robots="index, nofollow"
      />
      <Container className="margins">
        <Wrapper>
          <Widget
            id="V4Q76CDM"
            className="typeform-widget"
            style={{
              width: "100%",
              height: "600px",
            }}
          />
        </Wrapper>
      </Container>
    </>
  )
}

export default SignupPage
